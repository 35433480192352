const appData = [
    { 
        page: 'home',
        title: 'Amirhossein Rahmati',
        content: 'Front-end web developer specializing in modern JavaScript frameworks. Architect and develop complex user interfaces for web applications with a particular focus on React. Flawless execution from concept to launch ensures optimal results for clients.', 
        character: 'A',
        pageTitle: 'Amirhossein Rahmati | Front-End Web Developer'
    },
    
    {
        page: 'contact',        
        title: 'Contact',
        content: '<div><h2>Email</h2><a href="mailto:mramirhosseinrahmati@gmail.com">mramirhosseinrahmati@gmail.com</a></div><div><h2>Linkedin</h2><a href="https://www.linkedin.com/in/amirhosseinrahmati/" target="_blank">https://www.linkedin.com/in/amirhosseinrahmati/</a></div><div><h2>Github</h2><a href="https://github.com/amirhosseinrahmati" target="_blank">https://github.com/amirhosseinrahmati</a></div>',
        character: '@',
        pageTitle: 'Contact | Amirhossein Rahmati'
    },
    
    {
        page: 'work',
        title: 'Work Experience',
        content: '<div><h2>Senior Front-End Web Developer</h2><a href="http://obt.com.tr/" target="_blank">OPTİMUS YAZILIM - Istanbul, Turkey</a></div><div><h2>Senior Front-End Web Developer</h2><a href="https://www.courierit.co.nz/" target="_blank">PAYIT - Auckland, New Zealand</a></div><div><h2>Front-End Web Developer</h2><a href="https://digivega.com/" target="_blank">DIGIVEGA - Istanbul, Turkey</a></div><div><h2>Front-End Web Developer</h2><a href="https://www.weetech.co.uk/" target="_blank">WEETECH - Glasgow, Scotland</a></div><div><h2>Front-End Web Developer</h2><a href="#">PICAPIXLAB - Tabriz, Iran</a></div>',
        character: 'W',
        pageTitle: 'Work | Amirhossein Rahmati'
    },

    {
        page: 'uses',
        title: 'Uses',
        content: '<div><h2>Hardware & OS</h2><span>Notebook - Windows 10</span></div><div><h2>Editor & Terminal</h2><span>VS Code (Ariake Dark) - Git Bash</span></div><div><h2>Design</h2><span>Adobe Photoshop - Adobe XD - Figma</span></div>',
        character: 'U',
        pageTitle: 'Uses | Amirhossein Rahmati'
    }
];

export default appData;
